<template>
    <div class="content">
    <div class="row">
      <div class="col-2">
          <el-dropdown style="width:100%" @command="handleCommand">
            <el-button type="primary" size="mini" style="width:100%">
              Acciones<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="descarga" v-if="permisos.down">Descargar Filtro</el-dropdown-item>
              <el-dropdown-item command="resultados" v-if="permisos.down" >Descargar Resultados</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        Regiones:
          <el-select v-model="region" placeholder="REGIONES" size="mini" @change="getGerencias(0)">
            <el-option
              v-for="item in regiones"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
        <span v-if="gerencias.length != 0">Gerencias:</span>
          <el-select v-if="gerencias.length != 0" v-model="gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(0)">
            <el-option
              v-for="item in gerencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="cedis.length != 0">Cedis:</span>
          <el-select v-if="cedis.length != 0" v-model="cedi" placeholder="CEDIS" size="mini" @change="getPreventa(0)">
            <el-option
              v-for="item in cedis"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="preventas != 0">Ruta:</span>
          <el-select v-if="preventas != 0" v-model="pre" placeholder="RUTA" size="mini" multiple collapse-tags>
            <el-option
              v-for="item in preventas"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Fechas:
            <el-date-picker
              type="daterange"
              placeholder="Date Time Picker"
              v-model="dateTimePicker"
               size="mini"
               format="dd-MM-yyyy"
               value-format="yyyy-MM-dd"
               prefix-icon="false"
               class="el-input__icon"
               range-separator="A"
               style="font-size:8px; padding:3px 0px 3px 6px;"
            >
            </el-date-picker>
          <el-button size="mini" type="primary" class="mt-3" style="width:100%"
          @click="filtrar"
          >
            Filtrar
          </el-button>
        <p>Ingrese un Nombre</p>
        <el-input
          placeholder="Nombre"
          v-model="findNud" size="mini"
          @focus ="findName = ''"
          >
        </el-input>
       <p>Ingrese NUD</p>
        <el-input
          placeholder="Nombre"
          v-model="findName" size="mini"
          @focus="findNud = ''"
          >
        </el-input>
            <el-button size="mini" type="primary" class="mt-3" style="width:100%"
            @click="filtrar"
            >
              Buscar
            </el-button>
        </div>

      <div class="col-10">
          <v-data-table
            dense
            :headers="headers"
            :items="desserts"
            item-key="valCar_id"
            class="elevation-1"
            :item-class="rowClass"
            :options="pagination"
            hide-default-footer
            @click:row="eventRow"
          >
            <template v-slot:item.car_val_status="{ item }" >
              {{ item.car_val_status == 3 ? 'Capturado': item.car_val_status == 2 ? 'Rechazado' : item.car_val_status == 6 ? 'Pen. por Validar' : 'Por Revisar' }}
            </template>
            <template v-slot:item.car_foto1="{ item }" >
              {{ item.car_foto1 !== '' ? 'Sí' : 'No' }}
            </template>
            <template v-slot:item.car_gps="{ item }">
              {{ item.car_gps !== '' ? 'Sí': 'No' }}
            </template>
          </v-data-table>
          <div
            slot="footer"
            class="col-12 my-2 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Registros <b>{{ start + 1 }}</b> A <b>{{ start + itemsT }}</b> de <b>{{ total }}</b>
              </p>
            </div>
            <b-pagination
              v-model="pagination.currentPage"
              :per-page="pagination.limit"
              :total-rows="total"
              size="sm"
              @change="newPagina"
              ></b-pagination>
          </div>
          </div>
          <!---->
    </div>
    <div class="row">
      <div class="col-10 offset-2">
        <v-card
          elevation="1"
          loading
          outlined
          style="padding:30px"
        >
        <v-overlay :value="loadBus" absolute>
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
            <v-data-table
              dense
              :headers="headersSc"
              :items="dessertsSc"
              item-key="valCar_uid"
              class="elevation-1"
              hide-default-footer
            >
            </v-data-table>
        </v-card>
      </div>
    </div>
    <v-overlay :value="overlay" z-index="2002">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    </div>
</template>

<script>
import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog } from 'element-ui';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import axios from 'axios';
import { Modal } from 'src/components';
// import { API_KEY } from "./API_KEY";
import { Loader, LoaderOptions } from "google-maps";

const loader = new Loader("AIzaSyDnsLdCKdxoFbJMxVfZHMDnr_SB-L-T7nA");

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog
  },
  data() {
    return {
      overlay: false,
      headers: [
        {
          text: 'Cartera ID',
          align: 'start',
          sortable: false,
          value: 'car_id',
          width: 150
        },
        { text: 'ID', value: 'valCar_id', width: 100, sortable: false },
        { text: 'Región', value: 'valCar_reg', width: 150, sortable: false },
        { text: 'Gerencia', value: 'valCar_gerencia', width: 150, sortable: false },
        { text: 'Cedis', value: 'valCar_cds', width: 200, sortable: false },
        { text: 'Estatus Visita', value: 'valCar_status_abrio', sortable: false },
        { text: 'Estatus Validación', value: 'valCar_val_status', width: 150, sortable: false },
        { text: 'Razón de Baja', value: 'valCar_razon_baja', sortable: false, width: 200 },
        { text: 'Intentos llamada', value: 'valCar_intentos', sortable: false },
        { text: 'Ruta', value: 'valCar_rt_prev', sortable: false, width: 100 },
        { text: 'NUD', value: 'valCar_NUD', sortable: false },
        { text: 'Nombre', value: 'valCar_nombre', width: 300, sortable: false },
        { text: 'Dirección', value: 'valCar_dir', width: 250, sortable: false },
        { text: 'Num Ext', value: 'valCar_num_ext', sortable: false,width: 100 },
        { text: 'Num Int', value: 'valCar_num_int', sortable: false },
        { text: 'Colonia', value: 'valCar_col', width: 250, },
        { text: 'Calle 1', value: 'valCar_cll_1', width: 250, },
        { text: 'Calle 2', value: 'valCar_cll_2', width: 250, },
        { text: 'C.P', value: 'valCar_cp', sortable: false },
        { text: 'Frec. Visita', value: 'valCar_frec_vis', sortable: false },
        { text: 'Categoría', value: 'valCar_suscripcion', sortable: false },
        { text: 'Teléfono 1 Fijo', value: 'valCar_tel_mot', sortable: false },
        { text: 'Teléfono 2 Celular', value: 'valCar_teladi', sortable: false },
        { text: 'Teléfono 3 Fijo', value: 'valCar_tel_3', sortable: false },
        { text: 'Teléfono 4 Celular', value: 'valCar_tel_4', sortable: false },
        { text: 'Comentario', value: 'valCar_comments', width: 250, sortable: false },
        { text: 'Giro', value: 'valCar_giro', sortable: false },
        { text: 'Correo', value: 'valCar_email', sortable: false },
        { text: '# Consumo', value: 'valCar_consumo', sortable: false },
        { text: 'Tipo Inventario', value: 'valCar_tipo_inventario', sortable: false },
        { text: 'Marca Consumo', value: 'valCar_marca_consumo', sortable: false },
        { text: 'Costo', value: 'valCar_costo', sortable: false },
        { text: '# Habitantes', value: 'valCar_num_habitantes', sortable: false },
        { text: 'Factura', value: 'valCar_factura', sortable: false },
        { text: 'Foto 1', value: 'valCar_f1', sortable: false,width: 250 },
        { text: 'GPS', value: 'valCar_gps', sortable: false, width: 250 },
        { text: 'Usuario', value: 'valCar_uid', sortable: false },
        { text: 'Fecha Envio', value: 'valCar_upload_date', sortable: false },
        { text: 'Hora Envio', value: 'valCar_upload_time', sortable: false },
        { text: 'Archivo', value: 'valCar_bk', sortable: false },
        { text: 'Fecha Validación', value: 'valCar_val_date', sortable: false },
        { text: 'Hora Validación', value: 'valCar_val_time', sortable: false },
        { text: 'Update Massive', value: 'valCar_ms_updt', sortable: false },
        { text: 'Revisado Gepp', value: 'valCar_gepp_rev', sortable: false,width: 150 },
        { text: 'Archivo', value: 'valCar_s3_bucket', sortable: false },
        ],
      desserts: [],
      headersSc: [
        { text: 'Estatus validacion', value: 'valCar_val_status', width: 150, sortable: false },
        { text: 'Revisado Gepp', value: 'valCar_gepp_rev' , width: 150, sortable: false },
        { text: 'Usuario', value: 'valCar_uid' , width: 150, sortable: false },
        { text: 'Ruta', value: 'valCar_rt_prev' , width: 150, sortable: false },
        { text: 'NUD', value: 'valCar_NUD'    , width: 150, sortable: false },
        { text: 'Nombre', value: 'valCar_nombre' , width: 150, sortable: false },
        { text: 'Direccion', value: 'valCar_dir' , width: 150, sortable: false },
        { text: 'Num. Ext.', value: 'valCar_num_ext' , width: 150, sortable: false },
        { text: 'Num. Int.', value: 'valCar_num_int' , width: 150, sortable: false },
        { text: 'Colonia', value: 'valCar_col' , width: 150, sortable: false },
        { text: 'Calle 1', value:  'valCar_cll_1' , width: 150, sortable: false },
        { text: 'Calle 2', value: 'valCar_cll_2' , width: 150, sortable: false },
        { text: 'C.P.', value: 'valCar_cp' , width: 150, sortable: false },
        { text: 'Frecuecia', value: 'valCar_frec_vis' , width: 150, sortable: false },
        { text: 'Categoria', value: 'valCar_suscripcion' , width: 150, sortable: false },
        { text: 'Fecha Envio', value: 'valCar_upload_date' , width: 150, sortable: false },
        { text: 'Hora Envio', value: 'valCar_upload_time' , width: 150, sortable: false },
        { text: 'Region', value: 'valCar_reg' , width: 150, sortable: false },
        { text: 'Gerencia', value: 'valCar_gerencia' , width: 150, sortable: false },
        { text: 'Cedis', value: 'valCar_cds' , width: 150, sortable: false },
        { text: 'Estatus Visita', value: 'valCar_status_abrio' , width: 150, sortable: false },
        { text: 'Razon Baja', value: 'valCar_razon_baja' , width: 150, sortable: false },
        { text: 'Intentos llamada', value: 'valCar_intentos' , width: 150, sortable: false },
        { text: 'Telefono 1', value: 'valCar_tel_mot' , width: 150, sortable: false },
        { text: 'Telefono 2', value: 'valCar_tel_int' , width: 150, sortable: false },
        { text: 'Comentario', value: 'valCar_comments' , width: 150, sortable: false },
        { text: 'Giro', value: 'valCar_giro' , width: 150, sortable: false },
        { text: 'Correo', value: 'valCar_email' , width: 150, sortable: false },
        { text: '# Consumo', value: 'valCar_consumo' , width: 150, sortable: false },
        { text: 'Tipo Inventario', value: 'valCar_tipo_inventario' , width: 150, sortable: false },
        { text: 'Marca Consumo', value: 'valCar_marca_consumo' , width: 150, sortable: false },
        { text: 'Costo', value: 'valCar_costo' , width: 150, sortable: false },
        { text: '# Habitantes', value: 'valCar_num_habitantes' , width: 150, sortable: false },
        { text: 'Factura', value: 'valCar_factura' , width: 150, sortable: false },
        { text: 'Foto 1', value: 'valCar_f1' , width: 150, sortable: false },
        { text: 'GPS', value: 'valCar_gps' , width: 150, sortable: false },
        { text: 'Archivo', value: 'valCar_bk' , width: 150, sortable: false },
        { text: 'Fecha validacion', value: 'valCar_val_date' , width: 150, sortable: false },
        { text: 'Hora validacion', value: 'valCar_val_time' , width: 150, sortable: false },
        { text: 'Update Massive', value: 'valCar_ms_updt' , width: 150, sortable: false },
        { text: 'Archivo', value: 'valCar_s3_bucket', width: 150, sortable: false }
      ],
      dessertsSc: [],
      pageCount: 0,
      start: 0,
      total: 0,
      itemsT: 0,
      pagination: {
        page: 1,
        limit: 13,
        itemsPerPage: 13,
        currentPage: 1
      },
      isBusy: false,
      region: null,
      regiones: [],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
      pre: null,
      preventas: [
        ],
      nuevo: null,
      nuevos: [
      { i: -1, d: 'Todos' },
      { i:  0, d: 'Cartera' },
      { i:  1, d: 'Nuevos' }
        ],
      source: null,
      sources: [
      { i: -1, d: 'Todos' },
      { i: 0, d: 'App' },
      { i: 1, d: 'Facebook' },
      { i: 2, d: 'Web' },
      { i: 3, d: 'Portal' },
      { i: 4, d: 'Call Center' },
      { i: 5, d: 'Whats' },
      { i: 6, d: 'Transfer' },
      { i: 7, d: 'Lona' },
      { i: 8, d: 'App Promotor' },
      { i: 9, d: 'Socios Comerciales' },
      { i: 10, d: 'Campaña Digital' }
    ],
    dateTimePicker: [],
    hash: '',
    uid: '',
    mdSubir: false,
    mdFileXls: null,
    mdBuscar: '',
    mdRegion: null,
    mdRegiones: [],
    mdGerencia: null,
    mdGerencias: [],
    mdCedi: null,
    mdCedis: [],
    dgSubir: false,
    passSubir: null,
    dgEliminar: false,
    dgEliminarReg: false,
    passEliminar: '',
    idsEliminar: '',
    dgMasivo: false,
    passMasivos: '',
    dgCambio: false,
    resultadoMasivo: '',
    masvisoFileXls: null,
    dgDetalle: false,
    currentClient: '',
    currentImagen: '',
    permisos: {},
    findName: '',
    findNud: '',
    loadBus: false
    };
  },
  methods: {
    rowClass(item) {
      //if (!item) return
      if (item.car_val_status == '3') return 'table-primary'
      if (item.car_val_status == '2') return 'table-danger'
    },
    paginate(p) {
      console.log(p);
      this.pagination.page = p

      this.pagination.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    },
    getData() {
      //console.log(this.pre.join(','))
      //this.isBusy = true
      //this.overlay = true
      let json ={
        c: "carteraFin",
        cmd: "getCartFinDataHisot",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        //ip: "189.167.28.167",
        opr_id: 1,
        u_reg_id: this.region,
        ger_id: this.gerencia,
        cds_id: this.cedi,
        rta_id: this.pre ? this.pre.join(',') : '',
        textFH: this.findNud != '' ? this.findNud : '',
        textFNom: this.findName != '' ? this.findName : '',
        page: this.pagination.page,
        start: this.start,
        limit: this.pagination.limit,
        date_fr: this.dateTimePicker[0],
        date_to: this.dateTimePicker[1]
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data);
        //this.overlay = false
        var resp = data.data.rows
        //this.items = resp;
        //this.pagination.rowsTotal = data.data.results

        this.desserts = resp;
        this.pageCount = Math.ceil(data.data.results / this.pagination.limit)
        this.total = data.data.results
        this.itemsT = resp.length
        //this.overlay = false
        //this.isBusy = false
      })
    },
    getRegiones(){
      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{

          this.regiones = data.data
          //this.mdRegiones = data.data
          this.regiones.unshift({ i: -1, d: 'TODAS' })
          //this.mdRegiones.splice(0,1)
          
        
      })
    },
    getRegionesMd(){
      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
          this.mdRegiones = data.data
          //this.mdRegiones.splice(0,1)
      })
    },
    getGerencias(sc){

      if(this.region == -1){
        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.gerencias = []
        this.cedis = []
        this.preventas = []

        return false
      }

        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.cedis = []
        this.preventas = []


      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg: this.region ? this.region : null
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.gerencias = data.data
          this.gerencias.unshift({ i: -1, d: 'TODAS' })
        }else{
          this.mdGerencias = data.data
        }
      })
    },
    getCedis(sc){

      if(this.region == -1){
        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.gerencias = []
        this.cedis = []
        this.preventas = []

        return false
      }

        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.cedis = []
        this.preventas = []


      let json ={
        c: "geppValida",
        cmd: "getCedisJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        ger: this.gerencia ? this.gerencia : null
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.cedis = data.data
          this.cedis.unshift({ i: -1, d: 'TODOS' })
        }else{
          this.mdCedis = data.data
        }
      })
    },
    getPreventa(){

      if(this.cedi == -1){
        this.pre = null
        this.preventas = []

        return false
      }

        this.pre = null

      let json ={
        c: "geppValida",
        cmd: "getRtPrevJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: this.cedi
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data.data);
        this.preventas = data.data
        this.preventas.unshift({ i: -1, d: 'TODAS' })
      })
    },
    filtrar(){
      this.pagination.page = 1
      //this.pagination.currentPage = 1
      this.start = 0
      this.getData()
    },
    descargaFiltro(){
      var str = ''

      str += this.region ? "&u_reg_id="+this.region : ''
      str += this.gerencia ? "&ger_id="+this.gerencia : ''
      str += this.cedi ? "&cds_id="+this.cedi : ''
      str += this.pre && this.pre != '' ? "&rta_id="+this.pre.join(',')  : ''
      str += this.nuevo ? "&nue_id="+this.nuevo : ''
      str += this.source ? "&src_id="+this.source : ''
      str += this.dateTimePicker[0] ? "&date_fr="+this.dateTimePicker[0] : ''
      str += this.dateTimePicker[1] ? "&date_to="+this.dateTimePicker[1] : ''

      window.location = "https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?c=CarteraIni&cmd=getCartIniDataDownload&exec=self&uid="+this.uid+"&hash="+this.hash+"&opr_id=1"+str;
    },
    handleCommand(cm){
      switch (cm){
        case 'descarga':
          this.descargaFiltro()
        break
        case 'subir':
          this.dgSubir = true
          this.getRegionesMd()
        break
        case 'eliminar':
          this.dgEliminar = true
        break
        case 'cambiar':
          this.dgMasivo = true
        break
      }
    },
    eventRow(record) {
      //console.log(this.pre.join(','))
      //this.isBusy = true
      this.loadBus = true
      let json ={
        c: "carteraFin",
        cmd: "getCartFinDataInocnResul",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        //ip: "189.167.28.167",
        opr: 0,
        reg: this.region,
        ger: this.gerencia,
        cds: this.cedi,
        id: record.car_id
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data);
        this.loadBus = false
        var resp = data.data.rows
        //this.items = resp;
        //this.pagination.rowsTotal = data.data.results

        this.dessertsSc = resp;

        //this.overlay = false
        //this.isBusy = false
      })
    },
    newPagina(p){
      // console.log(p);
      this.pagination.page = p
      this.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    }
  },
  mounted() {

    if(localStorage.getItem('key')) {
      this.hash = localStorage.getItem('key')
      this.uid = localStorage.getItem('uid')
      // console.log( JSON.parse(localStorage.getItem('permission')));
      this.permisos = JSON.parse(localStorage.getItem('permission')).cartera_ini.actions

      var dat = new Date();
      var mo = (dat.getMonth() + 1) < 10 ? '0' + (dat.getMonth() + 1) : (dat.getMonth() + 1)
      var da = dat.getDate() < 10 ? '0' + dat.getDate() : dat.getDate()
      this.dateTimePicker[0] = dat.getFullYear() + '-' + mo + '-' + da
      this.dateTimePicker[1] = dat.getFullYear() + '-' + mo + '-' + da

       // console.log(this.permisos);
      this.getRegiones()
      this.getData()
    } else {
       this.$router.push('login');
    }

  }
}
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content{
  font-size: inherit !important;
}

.xs{
  min-width: 100px;
  text-justify: auto;
}

.sm{
  min-width: 150px;
  text-justify: auto;
}

.mb{
  min-width: 200px;
  text-justify: auto;
}

.lm{
  min-width: 250px;
  text-justify: auto;
}

.xl{
  min-width: 300px;
  text-justify: auto;
}

.table th {
  text-align: left;
}

.success {
  color: blue;
}

.select_0 {
  padding-right: 5px;
}

.select {
  padding-left: 0px;
  padding-right: 5px;
}

.el-input__icon{
  width: 0px;
}

span.el-range-separator{
  width: 15px;
}

.el-range-input{
  width: 50%;
}

.card-map {
  min-height: 150px;
  .map {
    height: 150px;
    width: 100%;
  }
}
</style>
