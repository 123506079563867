<template>
    <div class="content">
    <div class="row">
      <div class="col-2">
          <el-dropdown style="width:100%" @command="handleCommand">
            <el-button type="primary" size="mini" style="width:100%">
              Acciones<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="descarga" v-if="permisos.down">Descargar Filtro</el-dropdown-item>
              <el-dropdown-item command="eliminar" v-if="permisos.del">Eliminar Registros</el-dropdown-item>
              <el-dropdown-item command="cambiar" v-if="permisos.edit">Cambios Masivos Cartera</el-dropdown-item>
              <el-dropdown-item command="cargar" v-if="permisos.view">Cargar Cartera Generica (NUD)</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        Regiones:
          <el-select v-model="region" placeholder="REGIONES" size="mini" @change="getGerencias(0)">
            <el-option
              v-for="item in regiones"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
        <span v-if="gerencias.length != 0">Gerencias:</span>
          <el-select v-if="gerencias.length != 0" v-model="gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(0)">
            <el-option
              v-for="item in gerencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="cedis.length != 0">Cedis:</span>
          <el-select v-if="cedis.length != 0" v-model="cedi" placeholder="CEDIS" size="mini" @change="getPreventa">
            <el-option
              v-for="item in cedis"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="preventas != 0">Preventa:</span>
          <el-select v-if="preventas != 0" v-model="pre" placeholder="PREVENTA" size="mini" multiple collapse-tags>
            <el-option
              v-for="item in preventas"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Estatus:
          <el-select v-model="estatus" placeholder="ESTATUS" size="mini">
            <el-option
              v-for="item in itmEstatus"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <el-button size="mini" type="primary" class="mt-3" style="width:100%"
          @click="filtrar"
          >
            Filtrar
          </el-button>
          Ingresa un NUD:
          <el-input v-model="nudFind" placeholder="INGRESA UN NUD" size="mini"></el-input>
          <el-button size="mini" type="primary" class="mt-3" style="width:100%" @click="buscarNud" >
            Buscar
          </el-button>
        </div>

      <div class="col-10">
          <v-data-table
            dense
            :headers="headers"
            :items="desserts"
            item-key="car_id"
            class="elevation-1"
            :item-class="rowClass"
            :options="pagination"
            hide-default-footer
          >
            <template v-slot:item.car_val_status="{ item }" >
              {{ item.car_val_status == 3 ? 'Capturado': item.car_val_status == 2 ? 'Rechazado' : item.car_val_status == 6 ? 'Pen. por Validar' : 'Por Revisar' }}
            </template>
            <template v-slot:item.car_foto1="{ item }" >
              {{ item.car_foto1 !== '' ? 'Sí' : 'No' }}
            </template>
            <template v-slot:item.car_gps="{ item }">
              {{ item.car_gps !== '' ? 'Sí': 'No' }}
            </template>
          </v-data-table>
          <div
            slot="footer"
            class="col-12 my-2 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Registros <b>{{ start + 1 }}</b> A <b>{{ start + itemsT }}</b> de <b>{{ total }}</b>
              </p>
            </div>
            <b-pagination
              v-model="pagination.currentPage"
              :per-page="pagination.limit"
              :total-rows="total"
              size="sm"
              @change="newPagina"
              ></b-pagination>
          </div>
            <el-dialog
              title="Eliminar Registros"
              :visible.sync="dgEliminar"
              width="30%">
              <p>Contraseña</p>
              <el-input v-model="passEliminar" placeholder="Contraseña"></el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dgEliminar = false">Cancelar</el-button>
                <el-button type="primary" @click="validateEliminar">Confirmar</el-button>
              </span>
            </el-dialog>
            <el-dialog
              title="Eliminar Registros"
              :visible.sync="dgEliminarReg"
              width="30%">
              <p>Ingrese los Id a Eliminar</p>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="Ids separados por coma (,)"
                v-model="idsEliminar">
              </el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dgEliminarReg = false">Cancelar</el-button>
                <el-button type="primary" @click="eliminarRegistros">Eliminar</el-button>
              </span>
            </el-dialog>
            <el-dialog
              title="Cambios Masivos"
              :visible.sync="dgMasivo"
              width="30%">
              <p>Contraseña</p>
              <el-input v-model="passMasivos" placeholder="Contraseña"></el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dgMasivo = false">Cancelar</el-button>
                <el-button type="primary" @click="validateMasivo">Confirmar</el-button>
              </span>
            </el-dialog>
            <el-dialog
              title="Cambios Masivos"
              :visible.sync="dgCambio"
              width="30%">
              <el-upload
                class="upload-demo mb-3"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-change="masivoArchivo"
                >
                <el-button size="small" type="primary">Clic para seleccionar archivo</el-button>
                <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
              </el-upload>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="Resultados"
                v-model="resultadoMasivo">
              </el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dgCambio = false">Cancelar</el-button>
                <el-button type="primary" @click="cambiosMasivos">Enviar</el-button>
              </span>
            </el-dialog>
          </div>
          <!---->
           <modal
              :show.sync="mdSubir" 
              headerClasses="justify-content-center"
            >
              <h4 slot="header" class="title title-up">SUBIR CARTERA</h4>
              <!--action="#" method="#" @submit.prevent>-->
                <form class="form-horizontal" ref="formSubir">
                  <div class="row">
                    <label class="col-md-3 col-form-label">Región</label>
                    <div class="col-md-9">
                    <el-select v-model="mdRegion" placeholder="REGIONES" size="mini" @change="getGerencias(1)">
                        <el-option
                          v-for="item in mdRegiones"
                          :key="item.i"
                          :label="item.d"
                          :value="item.i">
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-3 col-form-label">Gerencia</label>
                    <div class="col-md-9">
                      <el-select v-model="mdGerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(1)">
                        <el-option
                          v-for="item in mdGerencias"
                          :key="item.i"
                          :label="item.d"
                          :value="item.i">
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-3 col-form-label">Cedis</label>
                    <div class="col-md-9">
                    <el-select v-model="mdCedi" placeholder="CEDIS" size="mini">
                      <el-option
                        v-for="item in mdCedis"
                        :key="item.i"
                        :label="item.d"
                        :value="item.i">
                      </el-option>
                    </el-select>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-3 col-form-label">Archivo</label>
                    <div class="col-md-9">
                      <el-upload
                        class="upload-demo"
                        :on-change="subirArchivo">
                        <el-button size="small" type="primary">Clic para abrir archivo</el-button>
                        <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
                      </el-upload>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 offset-4 mt-3">
                      <el-button type="info" size="mini" @click="subirCartera">Enviar Información</el-button>
                    </div>
                  </div>
                </form>
            </modal>

            <el-dialog
              title="Subir cartera"
              :visible.sync="dgSubir"
              width="30%">
              <span>Por favor verifique que sus registros no existan, de lo contrario eliminelos antes de subir cartera.</span>
              <p>Contraseña</p>
              <el-input v-model="passSubir" placeholder="Contraseña"></el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dgSubir = false">Cancelar</el-button>
                <el-button type="primary" @click="validateSubir">Confirmar</el-button>
              </span>
            </el-dialog>
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    </div>
</template>

<script>
import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog } from 'element-ui';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import axios from 'axios';
import { Modal } from 'src/components';
// import { API_KEY } from "./API_KEY";
import { Loader, LoaderOptions } from "google-maps";

const loader = new Loader("AIzaSyDnsLdCKdxoFbJMxVfZHMDnr_SB-L-T7nA");

export default {
  components: {
      Modal,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog
  },
  data() {
    return {
      overlay: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'car_id',
        },
        { text: 'Estatus Validación', value: 'car_val_status', width: 150, sortable: false },
        { text: 'Operación', value: 'car_oper', width: 150, sortable: false },
        { text: 'Región', value: 'car_reg', width: 150, sortable: false },
        { text: 'Gerencia', value: 'car_gerencia', width: 150, sortable: false },
        { text: 'Cedis', value: 'car_cds', width: 200, sortable: false },
        { text: 'Ruta Preventa', value: 'car_rt_prev', width: 150, sortable: false },
        //{ text: 'Source', value: 'car_sourse', width: 150, sortable: false },
        { text: 'Estatus Visita', value: 'car_status_abrio', sortable: false },
        { text: 'Razón de Baja', value: 'car_razon_baja', sortable: false },
        //{ text: 'Marca', value: 'valCar_pro_marca', sortable: false },
        //{ text: 'Garrafones Semana', value: 'valCar_pro_cuantos', sortable: false },
        { text: 'NUD', value: 'car_clave_clt', sortable: false },
        { text: 'Nombre', value: 'car_nombre', width: 300, sortable: false },
        { text: 'Dirección', value: 'car_dir', width: 250, sortable: false },
        { text: 'Num Ext', value: 'car_num_ext', sortable: false },
        { text: 'Num Int', value: 'car_num_int', sortable: false },
        { text: 'Colonia', value: 'car_col', width: 250, },
        { text: 'Calle 1', value: 'car_cll_1', width: 250, },
        { text: 'Calle 2', value: 'car_cll_2', width: 250, },
        { text: 'C.P', value: 'car_cp', sortable: false },
        { text: 'Frec. Visita', value: 'car_frec_vis', sortable: false },
        { text: 'Etiqueta', value: 'car_eti', sortable: false },
        { text: 'Teléfono', value: 'car_tel', sortable: false },
        { text: 'Teléfono2', value: 'car_tel_2', sortable: false },
        //{ text: 'Teléfono3', value: 'car_tel_3', sortable: false },
        //{ text: 'Teléfono4', value: 'car_tel_4', sortable: false },
        { text: 'Comentario', value: 'car_comments', width: 250, sortable: false },
        { text: 'Giro', value: 'car_giro', sortable: false },
        { text: 'Email', value: 'car_email', sortable: false },
        { text: 'Factura', value: 'car_factura', sortable: false },
        { text: 'Fecha Envío', value: 'car_upload_date', sortable: false },
        { text: 'Hora Envío', value: 'car_upload_time', sortable: false },
        { text: 'GPS Lat', value: 'car_coord_x', sortable: false },
        { text: 'GPS Lng', value: 'car_coord_y', sortable: false },
        ],
      desserts: [],
      pageCount: 0,
      start: 0,
      total: 0,
      itemsT: 0,
      pagination: {
        page: 1,
        limit: 13,
        itemsPerPage: 13,
        currentPage: 1
      },
      isBusy: false,
      region: null,
      regiones: [],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
      pre: null,
      preventas: [
        ],
      nuevo: null,
      nuevos: [
      { i: -1, d: 'Todos' },
      { i:  0, d: 'Cartera' },
      { i:  1, d: 'Nuevos' }
        ],
      estatus: null,
      itmEstatus: [
      { i: -1, d: 'Todos' },
      { i:  0, d: 'Por Revisar' },
      { i:  3, d: 'Transformado' },
      { i:  2, d: 'Baja' }
    ],
    nudFind: [],
    hash: '',
    uid: '',
    mdSubir: false,
    mdFileXls: null,
    mdBuscar: '',
    mdRegion: null,
    mdRegiones: [],
    mdGerencia: null,
    mdGerencias: [],
    mdCedi: null,
    mdCedis: [],
    dgSubir: false,
    passSubir: null,
    dgEliminar: false,
    dgEliminarReg: false,
    passEliminar: '',
    idsEliminar: '',
    dgMasivo: false,
    passMasivos: '',
    dgCambio: false,
    resultadoMasivo: '',
    masvisoFileXls: null,
    dgDetalle: false,
    currentClient: '',
    currentImagen: '',
    permisos: {}
    };
  },
  methods: {
    rowClass(item) {
      //if (!item) return
      if (item.car_val_status == '3') return 'table-primary'
      if (item.car_val_status == '2') return 'table-danger'
    },
    paginate(p) {
      console.log(p);
      this.pagination.page = p

      this.pagination.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    },
    getData() {
      //console.log(this.pre.join(','))
      //this.isBusy = true
      this.overlay = true
      let json ={
        c: "CarteraIniGenerico",
        cmd: "getCartIniData",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        //ip: "189.167.28.167",
        opr_id: 1,
        u_reg_id: this.region,
        ger_id: this.gerencia,
        cds_id: this.cedi,
        rta_id: this.pre ? this.pre.join(',') : '',
        sts_id: this.estatus,
        page: this.pagination.page,
        start: this.start,
        limit: this.pagination.limit
      };

      if(this.nudFind != ''){
        json.textN = this.nudFind
      }

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data);
        this.overlay = false
        var resp = data.data.rows
        //this.items = resp;
        //this.pagination.rowsTotal = data.data.results

        this.desserts = resp;
        this.pageCount = Math.ceil(data.data.results / this.pagination.limit)
        this.total = data.data.results
        this.itemsT = resp.length
        //this.overlay = false
        //this.isBusy = false
      })
    },
    getRegiones(sc){

      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        if(sc == 0){
          this.regiones = data.data
          //
          this.regiones.unshift({ i: -1, d: 'TODAS' })
          //this.mdRegiones.splice(0,1)
        }else if(sc == 1){
          this.mdRegiones = data.data
        }
          
        
      })
    },
    getGerencias(sc){
      this.gerencia = null
      if(this.region == -1){
        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.gerencias = []
        this.cedis = []
        this.preventas = []

        return false
      }

      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg: this.region ? this.region : this.mdRegion ? this.mdRegion : null
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.gerencias = data.data
          this.gerencias.unshift({ i: -1, d: 'TODAS' })
        }else{
          this.mdGerencias = data.data
        }
      })
    },
    getCedis(sc){
      
      
      if(this.gerencia == -1){
        this.cedi = null
        this.pre = null
        this.cedis = []
        this.preventas = []

        return false
      }

      let json ={
        c: "geppValida",
        cmd: "getCedisJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        ger: this.gerencia ? this.gerencia : this.mdGerencia ? this.mdGerencia : null
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.cedis = data.data
          this.cedis.unshift({ i: -1, d: 'TODOS' })
        }else{
          this.mdCedis = data.data
        }
      })
    },
    getPreventa(){
      let json ={
        c: "geppValida",
        cmd: "getRtPrevJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: this.cedi
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data.data);
        this.preventas = data.data
        this.preventas.unshift({ i: -1, d: 'TODAS' })
      })
    },
    filtrar(){
      this.pagination.page = 1
      this.start = 0
      this.nudFind = ''
      this.getData()
    },
    descargaFiltro(){
      var str = ''

      str += this.region ? "&u_reg="+this.region : ''
      str += this.gerencia ? "&ger="+this.gerencia : ''
      str += this.cedi ? "&cds="+this.cedi : ''
      str += this.pre && this.pre != '' ? "&rta_id="+this.pre : ''
      str += this.estatus ? "&sts_id="+this.estatus : ''

      window.location = this.$api_url+"?c=CarteraIni&cmd=getCartIniDataDownload&exec=self&uid="+this.uid+"&hash="+this.hash+"&opr=1"+str;
    },
    handleCommand(cm){
      switch (cm){
        case 'descarga':
          this.descargaFiltro()
        break
        case 'eliminar':
          this.dgEliminar = true
        break
        case 'cambiar':
          this.dgMasivo = true
        break
        case 'cargar':
          this.$refs.formSubir.reset()
          this.mdRegion = null
          this.mdGerencia = null
          this.mdCedi = null
          this.mdSubir = true
          this.mdFileXls = null
          this.getRegiones(1)
        break
      }
    },
    validateSubir(){
      if(this.passSubir == 'admin#'){
        this.dgSubir = false
        this.passSubir = ''
        this.mdSubir = true
      }else{
        //
        this.$notify({
            title: 'Error',
            message: 'Contraseña incorrecta, verifica e intenta de nuevo.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      }
    },
    validateEliminar(){
      if(this.passEliminar == 'admin#'){
        this.dgEliminar = false
        this.passEliminar = ''
        this.dgEliminarReg = true
      }else{
        //
        this.$notify({
            title: 'Error',
            message: 'Contraseña incorrecta, verifica e intenta de nuevo.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      }
    },
    validateMasivo(){
      if(this.passMasivos == 'admin#'){
        this.dgMasivo = false
        this.passMasivos = ''
        this.dgCambio = true
      }else{
        this.$notify({
            title: 'Error',
            message: 'Contraseña incorrecta, verifica e intenta de nuevo.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      }
    },
    subirArchivo(file){
      //console.log(file)
      this.mdFileXls = file
    },
    masivoArchivo(){
      this.masvisoFileXls = file
    },
    buscarNud(){
      this.pagination.page = 1
      this.start = 0
      this.getData()
    },
    eliminarRegistros(){
      let json ={
        c: "CarteraFin",
        cmd: "getUpEliminarCartera",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        id: this.idsEliminar
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
          this.$notify({
            title: '',
            message: 'Clientes Eliminados con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    cambiosMasivos(){
      let json ={
        c: "CarteraFin_all",
        cmd: "processFormCartera",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        id: this.idsEliminar,
        wnd_upld_xls: this.masvisoFileXls,
        wnd_upld_act: 2,
        wnd_upld_uid: this.uid
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    subirCartera(){
      let json ={
        c: "CarteraIni",
        cmd: "processForm",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        oper: 1,
        frm_ini_act: '0',
        frm_ini_xls: this.fileXls,
        u_reg: this.mdRegion,
        ger: this.mdGerencia,
        cds: this.mdCedi
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data.data);
        this.$notify({
            title: '',
            message: 'Cartera cargada con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    newPagina(p){
      // console.log(p);
      this.pagination.page = p
      this.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    },
  },
  mounted() {

    if(localStorage.getItem('key')) {
      this.hash = localStorage.getItem('key')
      this.uid = localStorage.getItem('uid')
      // console.log( JSON.parse(localStorage.getItem('permission')));
      this.permisos = JSON.parse(localStorage.getItem('permission')).cartera_ini.actions

        //console.log(this.permisos);
      this.getRegiones(0)
      this.getData()
    } else {
       this.$router.push('login');
    }

  }
}
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content{
  font-size: inherit !important;
}

.xs{
  min-width: 100px;
  text-justify: auto;
}

.sm{
  min-width: 150px;
  text-justify: auto;
}

.mb{
  min-width: 200px;
  text-justify: auto;
}

.lm{
  min-width: 250px;
  text-justify: auto;
}

.xl{
  min-width: 300px;
  text-justify: auto;
}

.table th {
  text-align: left;
}

.success {
  color: blue;
}

.select_0 {
  padding-right: 5px;
}

.select {
  padding-left: 0px;
  padding-right: 5px;
}

.el-input__icon{
  width: 0px;
}

span.el-range-separator{
  width: 15px;
}

.el-range-input{
  width: 50%;
}

.card-map {
  min-height: 150px;
  .map {
    height: 150px;
    width: 100%;
  }
}
</style>
