<template>
  <div class="content">
    <div class="row">
      <div class="col-2">
          <el-dropdown style="width:100%" @command="handleCommand">
            <el-button type="primary" size="mini" style="width:100%">
              Acciones<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <!--<el-dropdown-item command="descarga">Descargar Filtro</el-dropdown-item>-->
              <el-dropdown-item command="sube">Archivo CSV</el-dropdown-item>
              <!--<el-dropdown-item command="cambia">Cambios Masivos</el-dropdown-item>-->
              <!--<el-dropdown-item command="cambiar">Cambios Masivos</el-dropdown-item>
              <el-dropdown-item command="asignar">Re Asignar</el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
        Regiones:
          <el-select v-model="region" placeholder="REGIONES" size="mini" @change="getGerencias(0)">
            <el-option
              v-for="item in regiones"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
        <span v-if="gerencias.length != 0">Gerencias:</span>
          <el-select v-if="gerencias.length != 0" v-model="gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(0)">
            <el-option
              v-for="item in gerencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="cedis.length != 0">Cedis:</span>
          <el-select v-if="cedis.length != 0" v-model="cedi" placeholder="CEDIS" size="mini" @change="getPreventa()">
            <el-option
              v-for="item in cedis"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="preventas != 0">Preventa:</span>
          <el-select v-if="preventas != 0" v-model="pre" placeholder="PREVENTA" size="mini" multiple collapse-tags>
            <el-option
              v-for="item in preventas"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Frecuencia:
          <el-select v-model="frecuencia" placeholder="FRECUENCIA" size="mini" >
            <el-option
              v-for="item in frecuencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Estatus:
          <el-select v-model="estatus" placeholder="ESTATUS" size="mini">
            <el-option
              v-for="item in itmEstatus"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Razón Baja:
          <el-select v-model="baja" placeholder="Razón Baja" size="mini">
            <el-option
              v-for="item in bajas"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <el-button size="mini" type="primary" class="mt-3" style="width:100%"
          @click="filtrar"
          >
            Mapear
          </el-button>
        </div>
      <div class="col-10">
                <div class="row">
                      <div class="col-md-12">
                        <card type="plain">
                          <div id="customSkinMap" class="map"></div>
                        </card>
                      </div>
                </div>
      </div>
    </div>

    <!-- modals-->

    <el-dialog
       title="Mapear CSV"
       :visible.sync="fmCambios"
       width="30%">
       <el-upload
         class="upload-demo mb-3"
         action="https://jsonplaceholder.typicode.com/posts/"
         :on-change="masivoArchivo"
         >
         <el-button size="small" type="primary">Clic para seleccionar archivo</el-button>
         <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
       </el-upload>
       <el-input
         type="textarea"
         :rows="2"
         placeholder="Resultados"
         v-model="resultadoMasivo">
       </el-input>
       <span slot="footer" class="dialog-footer">
         <el-button @click="fmCambios = false">Cancelar</el-button>
         <el-button type="primary" @click="cambiosMasivos">Enviar</el-button>
       </span>
     </el-dialog>
    <el-dialog
       title="Subir Clientes"
       :visible.sync="fmSubir"
       width="30%">
       <el-upload
         class="upload-demo mb-3"
         action="https://jsonplaceholder.typicode.com/posts/"
         :on-change="subirArchivo"
         >
         <el-button size="small" type="primary">Clic para seleccionar archivo</el-button>
         <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
       </el-upload>
       <!--<el-input
         type="textarea"
         :rows="2"
         placeholder="Resultados"
         v-model="resultadoMasivo">
       </el-input>-->
       <span slot="footer" class="dialog-footer">
         <el-button @click="fmSubir = false">Cancelar</el-button>
         <el-button type="primary" @click="subirClientesCsv">Enviar</el-button>
       </span>
     </el-dialog>
    <el-dialog
      title="Subir Clientes"
      :visible.sync="dgSubir"
      width="30%">
      <p>Contraseña</p>
      <el-input v-model="passSubir" placeholder="Contraseña"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgSubir = false">Cancelar</el-button>
        <el-button type="primary" @click="subirClientes">Confirmar</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Cambios Masivos"
      :visible.sync="dgCambios"
      width="30%">
      <p>Contraseña</p>
      <el-input v-model="passCambios" placeholder="Contraseña"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgCambios = false">Cancelar</el-button>
        <el-button type="primary" @click="confCambios">Confirmar</el-button>
      </span>
    </el-dialog>
    <!-- end modals-->
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog, FormItem, Form } from 'element-ui';
import axios from 'axios';

import { Loader, LoaderOptions } from "google-maps";
const loader = new Loader("AIzaSyDnsLdCKdxoFbJMxVfZHMDnr_SB-L-T7nA");

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [FormItem.name]: FormItem,
    [Form.name]: Form
  },
  data() {
    return {
      overlay: false,
      uid: null,
      hash: null,
      lvl: null,
      loading: true,
      region: null,
      regiones: [
      ],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
    dateTimePicker: [],
      desserts: [],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'val_id',
        },
        { text: 'Región', value: 'val_region', width: 100, sortable: false },
        { text: 'Gerencia', value: 'val_gerencia', width: 150, sortable: false },
        { text: 'Cedis', value: 'val_cedis', width: 200, sortable: false },
        { text: 'NUD', value: 'val_nud', sortable: false },
        { text: 'Cliente', value: 'val_cliente', width: 300, sortable: false },
        { text: 'Fecha', value: 'val_fecha', sortable: false },
        { text: 'Teléfono 1', value: 'val_tel_1', width: 100, sortable: false },
        { text: 'Teléfono 2', value: 'val_tel_2', width: 100, sortable: false },
        { text: 'Source', value: 'val_source', width: 150, sortable: false }
      ],
      pageCount: 0,
      start: 0,
      total: 0,
      itemsT: 0,
      pagination: {
        page: 1,
        limit: 13,
        itemsPerPage: 13,
        currentPage: 1
      },
      dgFiltrar: false,
      resultadoMasivo: '',
      masvisoFileXls: null,
      fmSubir: false,
      dgSubir: false,
      passSubir: '',
      subirFileXls: null,
      dgCambios: false,
      fmCambios: false,
      passCambios: '',
      preventas: [],
      pre: null,
      frecuencia: null,
      frecuencias: [
        { i: '-1',   d: 'Todos' },
        { i:  'L,J', d: 'L,J' },
        { i:  'M,V', d: 'M,V' },
        { i:  'X,S',d: 'X,S' }
      ],
      itmEstatus: [],
      estatus: null,
      baja: null,
      bajas: [],
      points: []
    }
  },
  methods: {
    handleCommand(cm){
      switch (cm){
        case 'descarga':
          this.descargaFiltro()
        break
        case 'sube':
          this.fmCambios = true
        break
        case 'cambia':
          this.dgCambios = true
        break
      }
    },

    filtrar() {
      this.getData()
    },
    getData() {
      this.overlay = true
      //console.log(this.pre.join(','))
     // this.isBusy = true
      let json ={
        c: "Maps",
        cmd: "getPointsQuery",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        //ip: "189.167.28.167",
        opr: 0,
        reg: this.region ? this.region : '',
        ger: this.gerencia ? this.gerencia : '',
        cd: this.cedi ? this.cedi : '',
        rt_id: this.pre ? this.pre : '',
        frc: this.frecuencia,
        estatus_id: this.estatus,
        razon_id: this.baja
      };



      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data.data);
        this.points = data.data
        this.loadMapa()
        this.overlay = false
      })
    },
    getRegiones(){
      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{

          this.regiones = data.data
          this.itemsRegiones = data.data
          this.regiones.unshift({ i: -1, d: 'TODAS' })
          this.itemsRegiones.splice(0,1)
          
        
      })
    },
    getGerencias(sc){

      if(this.region == -1){
        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.gerencias = []
        this.cedis = []
        this.preventas = []
        this.usuarios = []

        return false
      }

        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.gerencias = []
        this.cedis = []
        this.preventas = []


      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg: this.region ? this.region : this.clNuevo.region ? this.clNuevo.region : null
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.gerencias = data.data
          this.gerencias.unshift({ i: -1, d: 'TODAS' })
        }else{
          this.itemsGerencias = data.data
        }
      })
    },
    getCedis(sc){
      if(this.gerencia == -1){
        this.cedi = null
        this.cedis = []
        this.pre = null
        this.preventas = []
        return false
      }

        this.cedi = null
        this.cedis = []
        this.pre = null
        this.preventas = []

      let json ={
        c: "geppValida",
        cmd: "getCedisJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        ger: this.gerencia ? this.gerencia : this.clNuevo.gerencia ? this.clNuevo.gerencia : null
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.cedis = data.data
          this.cedis.unshift({ i: -1, d: 'TODOS' })
        }else{
          this.itemsCedis = data.data
        }
      })
    },
    getPreventa(){
      let json ={
        c: "geppValida",
        cmd: "getRtPrevJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: this.cedi
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data.data);
        this.preventas = data.data
        this.preventas.unshift({ i: -1, d: 'TODAS' })
      })
    },
    getEstatus(){
      let json ={
        c: "geppValida",
        cmd: "getEstFinJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data.data);
        this.itmEstatus = data.data
        this.itmEstatus.unshift({ i: -1, d: 'TODOS' })
      })
    },
    getRazon(){
      let json ={
        c: "geppValida",
        cmd: "getRazonFinJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data.data);
        this.bajas = data.data
        this.bajas.unshift({ i: -1, d: 'TODAS' })
      })
    },
    descargaFiltro(){
      var str = ''
      str += this.region ? "&reg="+this.region : 0
      str += this.gerencia ? "&ger="+this.gerencia : 0
      str += this.cedi ? "&cds="+this.cedi : 0
      str += this.dateTimePicker[0] ? "&date_fr="+this.dateTimePicker[0] : ''
      str += this.dateTimePicker[1] ? "&date_to="+this.dateTimePicker[1] : ''

      window.location = "https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?c=clientesValidados&cmd=getCartCapDataDownload&exec=self&uid="+this.uid+"&hash="+this.hash+"&lvl="+this.lvl+"&opr=0"+str;
    },
    
    cambiosMasivos(){
      let json ={
        c: "clientesValidados",
        cmd: "processForm",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        id: this.idsEliminar,
        wnd_upld_xls: this.masvisoFileXls,
        wnd_upld_act: 2,
        wnd_upld_uid: this.uid
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    masivoArchivo(file){
      this.masvisoFileXls = file
    },
    subirArchivo(file){
      this.subirFileXls = file
    },
    subirClientes(){
      if(this.passSubir == 'admin#'){
        this.dgSubir = false
        this.fmSubir = true
      }else{
        this.$notify({
          title: 'Error',
          message: 'Contraseña Incorrecta verifique y vuelva a intentar.',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 4000,
          icon: 'tim-icons icon-bell-55'
        });
      }
    },
    confCambios(){
      if(this.passCambios == 'admin#'){
        this.dgCambios = false
        this.fmCambios = true
      }else{
        this.$notify({
          title: 'Error',
          message: 'Contraseña Incorrecta verifique y vuelva a intentar.',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 4000,
          icon: 'tim-icons icon-bell-55'
        });
      }
    },
    subirClientesCsv(){
      let json ={
        c: "clientesValidados",
        cmd: "processForm",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        frm_ini_act: 0,
        frm_ini_xls: this.fileXls
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data.data);
        this.$notify({
            title: '',
            message: 'Clientes cargados con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    loadMapa(){
      var _self = this
    loader.load().then(function(google) {
      var pArr = []
      
      // Custom Skin & Settings Map Begin
      
       const myLatlng2 = new google.maps.LatLng(23.6, -102.5); //(23.6, -102.5);
      if(_self.points.length !== 0 ){
        for(var j in _self.points){
          var ob = {
            LatLng: new google.maps.LatLng(_self.points[j].latLng[0], _self.points[j].latLng[1]),
            title: _self.points[j].options.labelContent
          }
          pArr.push(ob)
        }
      }

      const mapOptions2 = {
        zoom: 5,
        center: myLatlng2,
        scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
        disableDefaultUI: true, // a way to quickly hide all controls
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      };

      //console.log(document.getElementById("customSkinMap"));
      const map2 = new google.maps.Map(
        document.getElementById("customSkinMap"),
        mapOptions2
      );

      if(pArr.length !== 0){
        for(var h in pArr){
          const m = new google.maps.Marker({
              position: pArr[h].LatLng,
              title: pArr[h].title
            });

          m.setMap(map2);
        }

      }else{
        const marker2 = new google.maps.Marker({
          position: myLatlng2,
          title: ""
        });

        marker2.setMap(map2);
      }

      // Custom Skin & Settings Map End
    });
    }
  },
  mounted () {
    if(localStorage.getItem('key')) {
      this.hash = localStorage.getItem('key')
      this.uid = localStorage.getItem('uid')
      this.lvl = localStorage.getItem('level')

    /* var dat = new Date();
      var mo = (dat.getMonth() + 1) < 10 ? '0' + (dat.getMonth() + 1) : (dat.getMonth() + 1)
      var da = dat.getDate() < 10 ? '0' + dat.getDate() : dat.getDate()
      this.dateTimePicker[0] = dat.getFullYear() + '-' + mo + '-' + da
      this.dateTimePicker[1] = dat.getFullYear() + '-' + mo + '-' + da*/

      this.getRegiones()
      this.getEstatus()
      this.getRazon()
      this.loadMapa(); //23.6, -102.5
    } else {
       this.$router.push('login');
    }
  }
}
</script>
<style>
.v-btn {
  max-height: 20px;
  text-align: center;
}

.v-btn__content {
   font-size: 10px;
}


.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
   /*font-size: 11px !important;
   height: 20px;*/
   text-align: left;
   background-color: rgba(0, 0, 0, .05);
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 11px !important;
  /* height: 22px;*/
}
/*
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
*/
.theme--light.v-pagination .v-pagination__item--active {
  background-color: #124A98;
  color: #fff;
}

  .map {
    height: 500px;
    width: 100%;
  }

</style>